import React from "react"
import { graphql, Link } from "gatsby"
import BackgroundImage from 'gatsby-background-image'

import Layout from '../components/Layout'
import "./../components/DynamicPage.css"




function GeneralTemplate(props) {
    let data = props.data.wpgraphql;
    
    function constructMetaData(page, currentPage) {
        let payload = {
            noIndex: page.seo.metaRobotsNoindex,
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://kleidaras-varvaridis.gr/${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://kleidaras-varvaridis.gr/${currentPage}`,
            fbType: "website",
            locale: 'el',
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }

    return (
        <div>

            <Layout 
                header={data.menu}
                footer={data.footer}
                services={data.services}
                productCategories={data.productCategories}
                metaData={constructMetaData(data.page, props.pageContext.currentPage)}
            >
                <div>
                    <div>
                        {data.page.dynamicPageAcf.introBackgroundImage && (
                            <BackgroundImage
                                Tag="section"
                                className="background-dm-container"
                                fluid={data.page.dynamicPageAcf.introBackgroundImage.imageFile.childImageSharp.gatsbyImageData}
                                backgroundColor={`#040e18`}
                            >
                                <div>
                                    <h1>
                                        {data.page.title}
                                    </h1>
                                </div>
                            </BackgroundImage>
                        )}
                    </div>
                    <div className="container m-auto py-16 dynamic-content" dangerouslySetInnerHTML={{ __html: data.page.content }}></div>
                </div>
            </Layout>
        </div>
    );
}

export default GeneralTemplate;

export const pageQuery = graphql`query GET_DYNAMIC_PAGE($id: ID!) {
  wpgraphql {
    menu: template(id:"cG9zdDozMTIz", idType:ID){
        menuAcf{
          menu{
            menuGroup{
              url
              label
              isnested
              childnodes{
                url
                label
              }
            }
          }
        }  
    }
    page(id: $id) {
      seo {
        canonical
        title
        metaDesc
        opengraphDescription
        opengraphTitle
        opengraphImage {
          sourceUrl
        }
        metaRobotsNoindex
      }
      title
      content
      dynamicPageAcf {
        introBackgroundImage {
          altText
          sourceUrl
          imageFile {
            childImageSharp {
              gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    services(first: 200, where: {orderby: {field: MENU_ORDER, order: ASC}}) {
        ...ServicesFragment
    }

    productCategories{
        edges{
            node{
                name
                slug
            }
        }
    }
    footer: template(id: "cG9zdDoyMjE5") {
      id
      title
      footerInformationTemplate {
        footerContent
      address {
        addressGoogleLink
        addressLabel
      }
      hoursLabel
      hoursTitle
      informationTitle
      supportEmail
      telephoneNumber
      servicesTitle
      generalPagesTitle
    }
    }
  }
}
`
